.heading-main {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  color: #242526;
  margin-top: 0px;
}

.table-vendor th {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 15px;
  color: #242526 !important;
}

.table-vendor td {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 15px;
  color: #B1B3B7 !important;
}

.vendor-table-card .green-cell {
  color: green !important;
}

.vendor-table-card .red-cell {
  color: red !important;
}

.vendor-table-card .blue-cell {
  color: blue !important;
}

.vendor-table-card .yellow-cell {
  color: yellow !important;
}

.table-vendor td:nth-child(3) {
  width: 100px;
}

.css-1ygcj2i-MuiTableCell-root {
  background: rgba(228, 230, 234, 0.35) !important;
}

.table-vendor h6 {
  font-size: 14px !important;
  color: #B1B3B7 !important;
}

.table-vendor td a {
  padding: 7px 12px !important;
  border-radius: 10px !important;
  font-family: 'Poppins' !important;
  font-size: 13px;
}

.table-vendor th:nth-child(4) {
  width: 100px;
}

.addnew {
  width: 150px;
  height: 40px;
  padding: 0 !important;
  display: flex !important;
  margin: 0px 0px 0px auto !important;
}

.domain-name {
  margin-bottom: 0px;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 10px;
}

.add-top {
  margin-top: 0px;
  font-family: 'Poppins' !important;
  font-style: normal;
}

.font-p {
  font-family: 'Poppins' !important;
  font-style: normal;
}

.flx-property {
  display: flex;
  /* height: 42vh; */
}

.vendordetail th {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 15px;
  color: #242526 !important;
}

.vendordetail td {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 15px;
}

.dark-mode-class .vendordetail td {
  color: #fff !important;
}

.dark-mode-class th {
  color: #fff !important;
}

.css-bj2p8a-MuiPaper-root-MuiCard-root {
  background: #1E1E1E !important;
}


.flx-property .MuiPaper-elevation1 {
  width: 100%;
}

.cover-table {
  border-radius: 15px;
  background: #fff;
  padding: 0px 0 0px;
}

.cover-table h3 {
  color: #242526;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
}

.dark-mode-class .cover-table {
  background: #242526;
}

.dark-mode-class .cover-table h3 {
  color: #fff;
}

.dark-mode-class .MuiTableRow-headth th {
  background: rgba(82, 79, 79, 0.14) !important;
}

.dark-mode-class .vendordetail th {
  color: #fff !important;
}

.dark-mode-class .total-score {
  color: #fff;
}

.vendor-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  color: #FFFFFF;
  margin-left: 12px;
  margin-bottom: 15px;
}

.full-coverdesign {
  box-shadow: 0px 0px 5px rgb(0 0 0 / 5%) !important;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 24px 0px !important;
}

.total-score {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 50px;
  color: #242526;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.domain-part {
  margin-left: 15px;
}

.manage-middle {
  position: relative;
}

.manage-mid a {
  text-decoration: none !important;
}

.vendordetail .css-1y04ugt-MuiTableCell-root {
  background: rgba(228, 230, 234, 0.35) !important;
}

.vendordetail .css-1drgtl0-MuiButtonBase-root-MuiIconButton-root {
  position: relative !important;
}

.vendordetail {
  padding: 0 !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
}

.css-rylm9l-MuiTableCell-root {
  background: rgba(228, 230, 234, 0.35) !important;
}

.css-1azl6jz-MuiTableCell-root {
  background: rgba(228, 230, 234, 0.35) !important;
}

.vendordetail .css-jhd7ab-MuiTable-root td:first-child {
  padding-bottom: 0px;
}

.dark-mode-class .vendordetail td a {
  color: #fff !important;
}

.css-1r7avug-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
}

.css-3sx0hq-MuiTabs-indicator {
  background-color: #4a10a4 !important;
}

#chart {
  max-width: 500px;
}

.tabspart .MuiTab-root {
  color: #242526 !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
}

.tabspart .Mui-selected {
  color: #4a10a4 !important;
  font-weight: 500;
}

.tabspart .MuiTabs-indicator {
  background-color: #4a10a4 !important;
}

.dark-mode-class .tabspart .Mui-selected {
  background-color: #18191a !important;
  color: #fff !important;
}

.dark-mode-class .tabspart button {
  color: #fff !important;
}

.compliance-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #242526 !important;
  font-family: 'Poppins';
  font-style: normal;
}

.compliance-inline a {
  text-decoration: none;
}

.click-to-begin {
  height: 40px;
  margin: 0px auto !important;
}

.Assessment-box {
  background-color: #fff;
  padding: 25px 35px 10px;
  border-radius: 15px;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #242526 !important;
  font-family: 'Poppins';
  font-style: normal;
}

.Assessment-box-attack {
  background-color: #fff;
  padding: 30px 30px;
  border-radius: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  color: #242526 !important;
  font-family: 'Poppins';
  font-style: normal;
  margin-top: 20px;
}

.send-assessment {
  width: 180px;
}

.manage-mid {
  text-align: center;
  margin-bottom: 25px;
}

.dark-mode-class .Assessment-box {
  background: #242526;
  border: 1px solid #3A3B3C;
}

.Assessment-box h2 {
  margin-bottom: 30px;
}

.dark-mode-class .Assessment-box h2,
.dark-mode-class .compliance-inline h2 {
  color: #fff;
}

.dark-mode-class .Assessment-box-attack {
  background: #242526;
  border: 1px solid #3A3B3C;
}

.Assessment-box-attack h2 {
  margin-bottom: 30px;
}

.dark-mode-class .Assessment-box-attack h2,
.dark-mode-class .compliance-inline h2 {
  color: #fff;
}


.box-Assessment {
  display: flex;
  width: 100%;
  align-items: center;
  background: #fff;
  /* box-shadow: 0px 0px 5px rgb(0 0 0 / 5%); */
  border-radius: 15px;
  /* padding: 0px 15px; */
  border: 1px solid #e4e6ea;
  margin-top: 0 !important;
  margin-bottom: 25px !important;
  margin-left: -12px !important;
}

.dark-mode-class .box-Assessment {
  background: #242526;
  border: 1px solid #3A3B3C;
}

.dark-mode-class .box-Assessment h5,
.dark-mode-class .box-Assessment h4 {
  color: #fff;
}


.box-Assessment-attack {
  display: flex;
  width: 100%;
  align-items: center;
  background: #fff;
  /* box-shadow: 0px 0px 5px rgb(0 0 0 / 5%); */
  border-radius: 15px;
  padding: 0px 15px;
  border: 1px solid #e4e6ea;
}

.dark-mode-class .box-Assessment-attack {
  background: #242526;
  border: 1px solid #3A3B3C;
}

.dark-mode-class .box-Assessment-attack h5,
.dark-mode-class .box-Assessment-attack h4 {
  color: #fff;
}

.Assessment-box-attack-a-tag {
  text-decoration: none;
}

.widthboxes {
  width: 100%;
  text-align: left;
}

.fitbutton {
  display: flex;
}

.fitbutton a {
  text-decoration: none;
}

.fitbutton button {
  margin: 0px 0px 0px 15px !important;
  width: 100px;
}

.widthboxes h5 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 20px;
}

.widthboxes h4 {
  font-size: 18px;
  font-weight: 500;
}

.widthboxes p {
  font-weight: 400;
  margin-top: 5px;
  font-size: 14px;
}

.wi-50 {
  width: 50%;
}

.Security-control {
  background-color: #fff;
  padding: 50px 35px;
  border-radius: 15px;
  width: 100%;
}

.Security-control a {
  text-decoration: none;
}

.box-Security-control {
  background: #FFFFFF;
  border: 1px solid #e4e6ea;
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 20px;
}

.box-Security-control h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  width: 100%;
  padding: 0px 7px;
  margin-bottom: 0px;
}

.fit-bottom {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.security-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #242526 !important;
  margin-top: 0px;
}

.dark-mode-class .security-text {
  color: #fff !important;
}

.flex-align {
  display: flex;
}

.dark-mode-class .Security-control {
  background: #242526;
  border: 1px solid #3A3B3C;
}

.dark-mode-class .box-Security-control {
  background: #242526;
  border: 1px solid #3A3B3C;
}

.m0-graph {
  height: auto !important;
}

.score-mail h4 {
  margin-top: 15px;
  margin-bottom: 0px;

}

.score-mail p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}

.bottom-circle {
  margin-left: -144px;
}

.icons-aligns {
  display: flex;
  justify-content: left;
  margin-top: 5px;
  margin-bottom: 10px;
  gap: 6px;
}

.icons-aligns svg {
  width: 20px;
  height: 20px;
  color: #4a10a4;
  margin-right: 9px;
}

.itm-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.dark-mode-class .svg-color svg {
  color: #fff;
}

.icons-aligns a {
  background-color: #4a10a4;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  text-transform: capitalize !important;
  padding: 1px 12px !important;
  text-decoration: none;
  margin-right: 6px;
}

.remove-color {
  background-color: #d32f2f !important;
}

.audits-images {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.audit-cards {
  text-align: center;
  border-right: 1px solid #f5f6f7;
}

.link-circle {
  /* background-color: #242526; */
  width: 23px;
  margin-inline: auto;
  border-radius: 100%;
  height: 23px;
  text-align: center;
  margin-top: 8px;
}

.link-circle svg {
  font-size: 17px;
  margin: 3px 0;
}

.row-grades {
  background-color: #4A10A4;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: block;
  color: #fff;
  text-align: center;
  line-height: 29px;
}

.audit-cards h3 {
  margin-bottom: 15px;
}

.dark-mode-class .audit-cards {
  border-right: 1px solid #3A3B3C;
}

.anchor-click {
  cursor: pointer;
}

.tab-anchor-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  padding-bottom: 0;
}

.tab-anchor-flex h4 {
  margin-bottom: 5px;
}

.tab-anchor-flex p {
  padding-top: 0px;
  margin-top: 0px;
}

.in-dataspace {
  padding: 15px;
}

.in-dataspace h4 {
  margin-bottom: 5px;
}

.in-dataspace p {
  margin-top: 0;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(0 0 0 / 25%) !important;
}

.space-cross {
  margin: 5px;
  cursor: pointer;
}

.icons-aligns-new a {
  padding: 0px !important;
}

.icons-aligns-new a:hover {
  background-color: rgb(231, 227, 227);
}

@media (min-width: 900px) {
  .flx-property {
    /* height: 364px; */
  }
}

@media (min-width: 1367px) {
  .flx-property {
    /* height: 464px; */
  }
}

@media (min-width: 1800px) {}

@media (max-width: 767px) {

  .bottom-circle {
    width: 6rem !important;
    height: 6rem !important;
    margin-left: -95px !important;
  }

  .issues-box h3 {
    font-size: 35px;
  }

  .security-text {
    font-size: 1.4rem !important;
  }

  .box-Assessment {
    padding: 15px;
  }

  .fitbutton button {
    margin: 0px 15px 0px 0px !important;
  }

  .Security-control {
    padding: 15px 15px;
  }

  .circularprogress {
    width: 6rem !important;
    height: 6rem !important;
  }

  .box-Security-control {
    padding: 25px;
  }

  .box-Security-control h2 {
    font-size: 16px;
  }

  .addnew {
    margin: 0px 0px 0px 0px !important;
  }

  .compliance-inline {
    display: block;
    margin-bottom: 15px;
  }

  .manage-mid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* .box-border-0:first-child{
margin-bottom: 25px;
} */

.score-flx {
  display: flex;
  align-items: center;
  height: 87%;
}

.flx-property .css-tchva {
  height: auto;
  margin-bottom: 25px;
}

.box-border-0 .box-Security-control {
  border: none;
  padding: 0;
}

.border-right-1 {
  border-right: 1px solid #d7d8d9;
  border-radius: 0px;
}

.subheader-scrore {
  margin: 0;
}

.fullcover-width {
  width: 100%;
  text-align: center;
}


.no-data-card {
  margin: auto;
  padding: 10px;
  height: 185px;
  display: flex;
  align-items: center;
}

.fullcover-width .MuiTypography-h5 {
  font-size: 16px !important;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .fullcover-width .MuiTypography-h5 {
    margin-bottom: 30px;
  }
}

.flex-domain {
  display: flex;
  align-items: center;
}

.domain-label-left {
  margin-left: 20px;
}

.flx-property .css-sfsq6u {
  height: auto;
  margin-bottom: 25px;
}

.ad-domain-btn {
  width: 252px;
  height: 53px;
}

.no-card-data {
  min-height: 180px;
  max-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.circularprogress {
  width: 7rem !important;
  height: 7rem !important;
}

.bottom-circle {
  width: 7rem !important;
  height: 7rem !important;
  margin-left: -112px !important;
}

.css-1yz83pi-MuiFormControl-root {
  margin-bottom: 9px !important;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight.MuiTableCell-sizeMedium.css-1i36psw {
  color: #000 !important;
}

span.MuiBox-root.css-mxncak {
  width: 57.97px;
}

span.MuiBox-root.css-jom3hb {
  width: 57.97px;
}


span.MuiBox-root.css-e95m82 {
  width: 57.97px;
}

.domain-label-left span.MuiBox-root.css-e95m82 {
  width: auto !important;
}

.domain-label-left span.MuiBox-root.css-ma9nno {
  width: auto !important;
}

span.MuiBox-root.css-m4fvc1 {
  width: 57.97px;
}

span.MuiBox-root.css-1dzhudb {
  width: 57.97px;
}

span.MuiBox-root.css-ma9nno {
  width: 57.97px;
}

.margin-ecosystem {
  margin-bottom: 20px;
}


.plane-send:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}


/* .dark-mode-class .vendor-back-btn{
  margin-left: 342px !important;
  color: #000 !important;
  
}
.light-mode-class .vendor-back-btn{
  margin-left: 342px !important;
} */