.image-button {
  position: relative;
  border-radius: 13px !important;
  border-color: #c4c4c4 !important;

}
.dark-mode-class .image-button {
  border-color: #575758 !important;

}

.image-container {
  width: auto;
  height: 100px;
  overflow: hidden;

}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  background-color: #878787;
}


.edit-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%, 27%);
  color: transparent;
  transition: color 0.3s;
}

.dark-mode-class .image-button:hover .edit-icon {
  color: #fff;
}

.light-mode-class .image-button:hover .edit-icon {
  color: #000;
}


.textarea-space textarea {
  padding: 8px;
  border: none;
}

.back-button .MuiButton-containedPrimary {
  line-height: normal !important;
  float: right;
}

.next-button {
  line-height: normal !important;
  float: right;
}