.questionnaire-cards {
    cursor: pointer;
    max-height: 122px;
    border-radius: 10px !important;
}
.set-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 43px;
    margin-bottom: 5px;
}
.set-image-container img {
    max-width: 100%;
    max-height: 100%;
}
.questionnaire-set-name {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
}