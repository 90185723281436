.card-public-url{
    width: 1000px;
    height: 430px;
    display: flex;
    justify-content: center;
}
.visit-link{
    width: 150px;
    height: 40px;
    padding: 0 !important;
    /* display: flex !important;
    margin: 6px 0px 0px 155px !important; */
}
.domain-manual{
    text-align: start;
    padding-left: 8px;
    color: #8e9198;
    margin-top: 10px;

}

.primary-domain-manual{
    text-align: start;
    padding-left: 8px;
    color: #8e9198;
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.fontSize{
    font-size: 17px !important;
}

.dark-mode-class
.ip-text{
    cursor: pointer;
    background: #000;
}


.light-mode-class
.ip-text{
    cursor: pointer;
    background: #efe0e0;
}

.dns-icon{
    color: #4A10A4
}