.toggleMode{
    padding-left: 25px;
    overflow-x: hidden;
}

.navBar {
    display: flex;
    flex-wrap: wrap;
    height: 85vh;
}

.navBar ul{
    padding-left: 20px;
    padding-right: 20px;
}

.navBar a{
font-family: 'Poppins';
font-style: normal;
font-weight: 500!important;
font-size: 15px;
line-height: 20px;
color: #242526;
margin-bottom: 10px;
}

.navBar .active{
background-color: #4A10A4!important;
color: #fff!important;
border-radius: 10px;
}
.section-bottom {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.logo-sidebar{
    margin:11px auto 30px;
    display: block;
    text-align: center;
}

.logo-sidebar img{
    width: 145.11px;
    height: 50px;
}

.css-qeglhl{
    width: auto!important;
    height: auto!important;
}

.username-profile .css-ixt6q8{
    background-color: #fff!important;
    border-top: 1px solid #E4E6EA;
    border-bottom: 1px solid #E4E6EA;
    border-radius: 0px!important;
    padding: 15px 0 15px 20px!important;
}

.dark-mode-sidebar .css-ixt6q8{
    background-color:#121212!important;
    border-top: 1px solid #242526;
    border-bottom: 1px solid #242526;
}

.dark-mode-sidebar .css-ixt6q8 h6{
    color: #fff;
}

.dark-mode-sidebar .navBar a{
    color: #fff;
}

.css-ixt6q8 h6{
    font-family: 'Poppins'!important;
    font-style: normal;
    font-weight: 400;
    font-size: 15px!important;
    line-height: 20px;
    color: #242526;
}

.toggleMode span{
    font-family: 'Poppins'!important;
    font-style: normal;
    font-weight: 400;
    font-size: 15px!important;
    line-height: 20px;
    color: #242526;  
}

.dark-mode-sidebar .toggleMode span{
    color: #fff;
}

.css-3mgqde-MuiPaper-root-MuiDrawer-paper{
    border-right: none!important;
}

.css-5yipe8-MuiPaper-root-MuiDrawer-paper{
    border-right-style: solid!important;
    border-right: 1px solid #242526!important;
}

.section-bottom .MuiFormControlLabel-labelPlacementEnd{
    flex-direction: row-reverse;
    margin-left: 0px!important;
    width: 100%;
    justify-content: space-between;
}


.css-1nobdqi.Mui-checked + .MuiSwitch-track{
    background-color: #4a10a4!important;
}

.css-1nobdqi.Mui-checked {
    color: rgb(255 255 255);
}

.css-19gndve {
    width: 15px;
    height: 15px;
}

.css-ecvcn9 {
    width: 58px;
    height: 34px;
}
 