.logo-center {
    display: block;
    text-align: center;
    margin: 0px auto;
    width: 150px;
    margin-bottom: 30px;
}

.quest-base {
    display: flex;
}

.quest-base p {
    padding: 0px 15px;
}

.full-width {
    width: 100%;
}


.logo-image-policy {
    /* width: 140px;
    height: 100px; */
    /* background-color: grey; */
    margin: 0px 15px;
}

.profile-logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /* position: absolute;
    top: 190px; */
    object-fit: contain;
    background-color: #878787;
}

.logo-align-middle {
    display: flex;
    align-items: center;
}

.audits-images {
    border-radius: 50%;
    background-color: #878787;
    max-width: 100px;
}

.flex-policy {
    display: flex;
}

.statics-links {
    width: 100%;
    padding: 0px 17px 20px;
}

.d-flex-logo {
    display: flex;
    justify-content: left;
    padding-left: 5px;
    overflow-x: auto;
}

.logos {
    text-align: center;
    margin: 10px;
    border: 1px solid #e1e1e1;
    padding: 3px;
    min-width: 149px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.logos h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.document-boxs {
    display: flex;
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.document-boxs p {
    margin: 0;
    line-height: 0;
}

.padding15 {
    padding: 15px;
}

.dark-mode-class .edit-button {
    position: absolute;
    right: 14px;
    top: 5px;
    color: #fff;
}

.light-mode-class .edit-button {
    position: absolute;
    right: 14px;
    top: 5px;
    color: #4a10a4;
}


.card-container::after {
    content: '';
    position: absolute;
    top: 16px;
    bottom: 16px;
    left: 67%;
    border-left: 2px solid #998e8e;
    /* Adjust the color and thickness as needed */
    transform: translateX(-50%);
}

.p-top-0 {
    padding-top: 0 !important;
}

.m-top-0 {
    margin-top: 0 !important;
}

.d-flex-logo::-webkit-scrollbar {
    width: 8px;
    height: 9px;
    cursor: e-resize;

}

.d-flex-logo::-webkit-scrollbar-track {
    background: transparent;
}


.d-flex-logo::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
    cursor: e-resize;
}


.d-flex-logo:-webkit-scrollbar-thumb:hover {
    background: #555;
}

.documents {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    padding: 6px 0px 0;
}

.light-mode-class .documents span {
    color: #000;
    font-size: 14px;
}

.document-downlaod {
    color: white;
    text-decoration: none;
}

p {
    word-break: break-all;
}

.sidebar-logo {
    background-color: #878787;
}


.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    /* You can adjust this based on your layout */
    width: 180px;
}

.ISO-private h3 {
    margin-bottom: 0px;
    margin-top: 0px;
    text-transform: capitalize;
}

.dark-mode-class .ISO-private p {
    margin-top: 5px;
    text-transform: capitalize;
    font-size: 15px;
}

.dark-mode-class .ISO-private svg {

    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
    text-transform: capitalize;
}

.light-mode-class .ISO-private svg {
    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
    text-transform: capitalize;
}

.sidebar-logos {
    border-radius: 50%;
    background-color: #878787;
    width: 60px;
    height: 60px;
}

.css-plnk1x-MuiPaper-root-MuiDrawer-paper {
    background-color: #18191a !important;
    background-image: none !important;
}