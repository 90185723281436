.flex-form-control {
    display: flex;
    align-items: center;
}

.width-full {
    width: 100%;
}

.apexcharts-toolbar {
    display: none !important;
}

/* @media (min-width: 1200px) {
    .topHeader-move {
        min-height: 55px !important;
        background: #f5f6f7;
    }

    .dark-mode-class .topHeader-move {
        background-color: transparent;
    }
}

.heading-main {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    color: #242526;
    margin-top: 0px;
}

.dark-mode-class .heading-main {
    color: #fff;
} */

.light-mode-class tspan{
    fill: #000 !important;
}
.dark-mode-class tspan{
    fill: #fff !important;
}