.css-1byr0tz {
    padding-top: 20px !important;
    background: #F5F6F7;
    padding-bottom: 0 !important;
    /* position: relative; */
}

.MuiPaper-elevation1 {
    background: #FFFFFF;
    box-shadow: none !important;
    border-radius: 15px !important;
}

.fit-height .MuiPaper-elevation1 {
    width: 100%;
}

.MuiTypography-root {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 25px;
    /* color: #242526; */
    padding-top: 0px !important;
    /* font-size: 15px!important; */
}

.left-fit-contain h6 {
    font-size: 14px !important;
    line-height: 17px;

}

.MuiAppBar-positionFixed {
    background: transparent !important;
    backdrop-filter: inherit !important;
}

.css-bqyarn-MuiPaper-root-MuiCard-root {
    padding-bottom: 0px !important;
}

.css-144g3e0-MuiPaper-root-MuiCard-root {
    padding-bottom: 0px !important;
}

.align-boxes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0px;
    padding: 25px 5px;
}

.vw-width {
    width: 100vh;
}

.vw-width h4 {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 19px;
    color: #242526;
    opacity: 1;
    margin-bottom: 6px;
}

.vw-width h6 {
    opacity: 1;
    font-weight: 400 !important;
}

.totalnumber h2 {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 45px !important;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #242526;
    opacity: 1;
}

.card-headerpart {
    display: flex;
    align-items: center;
    padding: 0px 10px 0 0;
    justify-content: space-between;
}

.min-width {
    width: 100px;
}

.css-1ex1afd-MuiTableCell-root {
    font-size: 12px !important;
}

.head-th th {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #242526;
}

.head-th td {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #B1B3B7 !important;
}

.css-1j30m50 {
    padding: 0 !important;
}

.right-search {
    position: absolute !important;
    right: 90px;
    top: 8px;
}

.flex-property .MuiPaper-elevation1 {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
}

.fit-height .MuiPaper-elevation1 {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
}

.left-fit-contain .MuiCard-root:first-child {
    margin-bottom: 15px;
}

.left-fit-contain .MuiPaper-elevation1 {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important;
}

body .left-fit-contain .line-dark {
    box-shadow: none !important;
    margin-bottom: 0px !important;
}

.MuiButton-containedPrimary {
    background-color: #4A10A4 !important;
    color: #fff !important;
    border-radius: 10px !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
    box-shadow: none !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    line-height: 30px !important;
}

.mtop {
    padding-top: 20px !important;
}

.flex-property {
    display: flex;
    width: 100%;
}

.css-10650aq {
    height: 100vh !important;
}

.w100 .MuiPaper-elevation1 {
    width: 100%;
}

.css-1cj3uuw-MuiButtonBase-root-MuiIconButton-root {
    cursor: pointer;
}

.css-1cj3uuw-MuiButtonBase-root-MuiIconButton-root:before {
    background: transparent !important;
}

.css-1y19tqg h6 {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 20px;
    /* color: #242526; */
}

.css-1y19tqg p {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 20px;
    /* color: #242526; */
}

.css-1ludl2-MuiStack-root a li {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 15px !important;
}

.heading-main {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    color: #242526;
    margin-left: 0px;
}



.contaner-box {
    background-color: #fff;
}

.fit-height {
    display: flex;
    /* flex-wrap: wrap;
    width: 100%; */
}

.fit-height .MuiCard-root {
    /* height: 265px; */
}

.fit-height .css-xqhl11 {
    height: auto !important;
}

.left-fit-contain {
    display: flex;
    flex-wrap: wrap;
}

.left-fit-contain .MuiCard-root {
    display: flex;
    align-items: center;
    width: 100%;
}

.dark-mode-class .vw-width h4 {
    color: #fff;
}

.dark-mode-class .totalnumber h2 {
    color: #fff;
}

.dark-mode-class .head-th th {
    color: #fff;
}

.apexcharts-legend-text {
    font-size: 15px !important;
    color: #242526 !important;
    margin-bottom: 8px !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
}

/* .css-pth2tn-MuiPaper-root-MuiPopover-paper h6{
color: #fff!important;
} */


.dark-mode-class p {
    color: #fff;
}

.dark-mode-class {
    background-color: transparent;
}

.dark-mode-class .heading-main {
    color: #fff;
}

.dark-mode-class .MuiPaper-elevation1 {
    background: #242526 !important;
    border: 1px solid #3A3B3C !important;
    width: 100%;
}

.light-mode-class .MuiPaper-elevation1 {
    /* background: #242526 !important; */
    /* border: 1px solid #3A3B3C !important; */
    width: 100%;
}

.dark-mode-class .line-dark {
    border: 0px !important;
}

.dark-mode-class .apexcharts-legend-text {
    color: #fff !important;
}


.dark-mode-searchbar .searchs-icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(23%) saturate(1192%) hue-rotate(160deg) brightness(115%) contrast(109%);
}

.fit-height .MuiInputBase-root {
    border-radius: 10px !important;
    color: #242526 !important;
    /* border: 0.5px solid #242526!important; */
}

.fit-height .MuiOutlinedInput-input {
    color: #242526 !important;
    font-size: 15px !important;
    font-family: 'Poppins' !important;
}

.dark-mode-class .MuiOutlinedInput-input {
    color: #fff !important;
}

.light-mode-class .MuiOutlinedInput-input {
    color: #000 !important;
}

.fit-height .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    color: #242526 !important;
    font-size: 15px !important;
    font-family: 'Poppins' !important;
}

.apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
    display: block !important;
}

.percentText {
    text-align: center;
    width: 100%;
    display: block;
    margin-top: -25px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
}

.textSize {
    font-size: 20px;
}

.searchbutton {
    line-height: 28px !important;
}

.card-full form {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 15px 0px;
}


.print-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #242526 !important;
    margin-top: 0px;
    margin-bottom: 0px !important;
}

.view-file {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .view-file a {
    position: absolute;
    right: 0px;
} */

.view-file svg {
    color: #dfdde4;
}

.pin-upload {
    background: grey !important;
    color: #dfdde4 !important;
    border: 1px dashed #8b8b8b !important;
    height: 35px;
    padding: 15px !important;
}

.inbutton {
    display: flex;
    text-align: right;
    justify-content: right;
}

.inbutton a {
    text-decoration: none;
}

.Executive {
    width: 168px !important;
}

.right-15 {
    margin-right: 15px;
}

.security-posture {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #242526 !important;
}

.export-ex .percentText {
    margin-top: -30px;
    margin-bottom: 7px;
}

.padding-issues {
    padding: 15px;
}

.bgprint {
    background: #f5f6f7;
}

.bgprint .heading-main {
    color: #242526;
}

canvas {
    display: none;
}

.bgprint .affecteds-part h3 {
    color: #242526 !important;
}

.bgprint .affecteds-part .target-color {
    color: #242526 !important;
}

.bgprint .issues-peraa {
    color: #242526 !important;
}

.issueskey h4 {
    color: #242526 !important;
}

.css-1u361qs-MuiCardHeader-root {
    color: #242526 !important;
}

.css-bj2p8a-MuiPaper-root-MuiCard-root .css-1qvr50w-MuiTypography-root {
    color: #fff !important;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root .css-1qvr50w-MuiTypography-root {
    color: #242526 !important;
}

.fit-height .css-xqhl11 {
    height: auto !important;
}

.bg-dark-assessment .css-wop1k0-MuiDataGrid-footerContainer {
    border-top: 0px !important;
}


.gobal-top-move {
    margin-bottom: 21px;
}

.bg-greycover {
    /* background: #f5f6f7;
    margin-left: auto;
    width: 13%; */
}



@media (min-width: 1200px) {
    .topHeader-move {
        min-height: 55px !important;
        background: #f5f6f7;
    }

    .dark-mode-class .topHeader-move {
        background-color: transparent;
    }
}


@media screen and (min-width: 1525px) and (max-width: 1799px) {
    #chart {
        max-width: 355px !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1698px) {
    .max-w-left {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .ml25 {
        margin-left: 20px;
    }
}


@media (min-width: 1800px) {
    .fit-height .MuiCard-root {
        /* height: 380px; */
    }

    .fit-height #chart {
        max-width: 460px !important;
    }
}


@media screen and (min-width: 1200px) and (max-width: 1500px) {
    .line-dark h2 {
        font-size: 26px !important;
    }

    .vw-width h4,
    .vw-width h6 {
        padding-left: 7px;
    }
}

@media (min-width: 600px) {
    .css-19r6kue-MuiContainer-root {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.public-space-top {
    margin-top: 110px !important;
}


@media screen and (min-width: 992px) and (max-width: 1699px) {

    .ipad-space {
        padding: 0px 20px 0 20px;
    }

    .css-1byr0tz {
        /* padding-top: 72px !important; */
    }

    .public-space-top {
        margin-top: 0 !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
    .line-dark h2 {
        font-size: 26px !important;
    }

    .vw-width h4,
    .vw-width h6 {
        padding-left: 7px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .ml25 {
        margin-left: 20px;
    }

    .right-search {
        right: 79px;
        top: 12px;
    }

    .dark-mode-sidebar .css-ixt6q8 {
        background-color: #353535 !important;
        border-top: 1px solid #424242;
        border-bottom: 1px solid #424242;
    }
}

@media screen and (min-width: 768px) and (max-width: 899px) {
    .fit-height .MuiCard-root {
        /* height: 480px !important; */
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .fit-height .MuiCard-root {
        /* height: 347px; */
    }

    .ipad-space {
        padding: 0px 20px 0 20px;
    }

    .css-1byr0tz {
        padding-top: 72px !important;
    }

    .heading-main {
        margin-left: 20px;
    }

    /* .left-fit-contain .MuiCard-root{
    height: 100px;
} */
    #chart {
        max-width: 430px !important;
    }

    .right-search {
        right: 79px;
        top: 12px;
    }

    .dark-mode-sidebar .css-ixt6q8 {
        background-color: #353535 !important;
        border-top: 1px solid #424242;
        border-bottom: 1px solid #424242;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .align-boxes {
        padding: 25px 0px;
        margin-top: 0px;
    }
}

@media (max-width: 767px) {
    .dark-mode-sidebar .css-ixt6q8 {
        background-color: #353535 !important;
        border-top: 1px solid #424242;
        border-bottom: 1px solid #424242;
    }

    .align-boxes {
        padding: 15px 0px;
        margin-top: 0px;
    }

    .totalnumber h2 {
        font-size: 30px !important;
    }

    .css-1byr0tz {
        padding-top: 73px !important;
    }

    .heading-main {
        font-size: 25px !important;
    }

    .left-fit-contain {
        padding-top: 0px !important;
    }

    .right-search {
        right: 61px;
        top: 13px;
    }

    .fit-height .MuiCard-root {
        /* height: 290px; */
    }

    .MuiTypography-root {
        font-size: 18px !important;
    }
}

.mbottom {
    padding-bottom: 20px;
}

.fit-height .css-1lzsus4 {
    height: auto;
}

.apx-legend-position-right {
    height: 100%;
    overflow-y: hidden !important;
    padding-right: 5px !important;
}

.css-1ex1afd-MuiTableCell-root {
    font-size: 0.875rem !important;
}

.dark-mode-class.upload-new-plus {
    background: #fff !important;
    color: #000 !important;
    border: 1px dashed #000 !important;
    height: 40px;
    padding: 15px !important;
    margin: 10px auto !important;
    display: flex !important;
    width: fit-content;
    font-size: 15px !important;
    font-weight: 400 !important;
}

.light-mode-class.upload-new-plus {
    background: #000 !important;
    color: #fff !important;
    border: 1px dashed #fff !important;
    height: 40px;
    padding: 15px !important;
    margin: 10px auto !important;
    display: flex !important;
    width: fit-content;
    font-size: 15px !important;
    font-weight: 400 !important;
}