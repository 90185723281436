.next-button {
    float: right;
    margin-top: auto;
}

.add-questionnaire-action .add-questionnaire-button {
    margin-right: 10px;
    border-radius: 10px;
}
.add-questionnaire-action img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.add-questionnaire-button {
    line-height: 30px !important;

}

.link-remove {
    text-decoration: none;
}

.add-Questionnaire {
    width: 200px;
}

.d-justifybetween {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.accordion-part {
    margin: 15px 0px;
}

.accordion-part .MuiPaper-elevation1 {
    background-color: #f5f6f7;
}

.section-headingpart {
    padding-top: 15px;
    display: block;
    font-size: 16px;
}

.sub-categoryname {
    font-size: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 0;
}

.addsection {
    height: 40px;
}

.min-h40 {
    height: 40px;
}

.full-form .title-label {
    font-style: normal;
    font-weight: 400 !important;
}

.full-form {
    margin: 15px 5px;
}

.expand-section {
    font-weight: 400 !important;
    line-height: 38px;
}

.padding-space {
    padding: 10px;
}

.textarea-space textarea {
    padding: 12px 12px 0px;
}


.margin-questionnaire {
    margin-bottom: 20px;
}

.full-form input {
    color: #000!important;
}

.full-form textarea {
    color: #000!important;
}

textarea#\:r1\: {
    border: none;
}


/* .css-wywq3z-MuiSelect-select-MuiInputBase-input{
    color: #fff !important;
} */