.toggleMode .MuiFormControlLabel-labelPlacementEnd {
    margin-right: 0px !important;
}

/* .drawer-right .MuiPaper-rounded {} */

.pl-0 {
    padding-left: 10px !important;
}

.downlod-btn {
    color: #fff;
    font-size: 14px !important;
}

.discription-text {
    font-size: 14px;
}

.drawer-right {
    padding-right: 5px !important;
}

.active-border-line {
    -webkit-box-shadow: 2px 1px 21px -4px rgba(75, 16, 164, 0.73) !important;
    -moz-box-shadow: 2px 1px 21px -4px rgba(75, 16, 164, 0.73) !important;
    box-shadow: 2px 1px 21px -4px rgba(75, 16, 164, 0.73) !important;
}

.dark-mode-class .active-border-line {
    -webkit-box-shadow: 2px 1px 21px -4px rgba(75, 16, 164, 0.73) !important;
    -moz-box-shadow: 2px 1px 21px -4px rgba(75, 16, 164, 0.73) !important;
    box-shadow: 2px 1px 21px -4px rgb(168 166 171 / 73%) !important;
}



.scroll-container {
    width: 32%;
    height: 300px;
    overflow-y: scroll;
    transform: rotate(90deg);
}

.scroll-content {
    width: 140px;
    height: 100%;
    margin: 10px;
    background-color: #a8a8df;
    transform: rotate(-90deg);
}

.light-background-appbar {
    background: #fff
}

.dark-background-appbar {
    background: #000
}

.dark-background-appbar svg {
    color: #fff;
}

.dark-background-appbar .toggleMode span {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 20px;
    color: #fff;
}

.light-mode-heading {
    color: #000;
}

.dark-mode-heading {
    color: #fff;
}

.dark-mode-heading .MuiTypography-root {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 25px;
    padding-top: 0px !important;
}

.text-container {
    width: 300px;
    /* Adjust the width as per your design */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.border-public .MuiPaper-elevation1 {
    border: 1px solid #e1e1e1;
    border-radius: 6px;
}

/* .css-2y5mml-MuiPaper-root-MuiCard-root.MuiPaper-elevation1 {
    border: 1px solid #3A3B3C;
} */

.btn-new {
    width: 150px;
    height: 40px;
    padding: 0 !important;
    display: flex !important;
}

.large-text {
    font-size: 24px !important;
}

.public-page-logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: contain;
    border: 1px solid #878787;
    background: #f5f5f5;
}