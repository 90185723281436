.addfile{
    /* min-width: 100%;
    padding: 50px 35px; */
    margin-top: 40px;
}

.bgaddfiles{
    background-color: #fff;
    min-width: 100%;
    /* padding: 35px 35px; */
    border-radius: 15px;
    /* margin-top: 24px; */
    text-align: center;
}

.select-jeson {
    line-height: 35px!important;
    width: 169px;
}

.centerpoint{
    text-align: center;
    width: 100%;
    margin-bottom: 0!important;
}

/* .centerpoint h2{
    margin-bottom: 5px!important;
} */

.text-centerfeild {
    max-width: 50%;
    margin-bottom: 25px!important;
}

.save-btn-block{
display: block;
margin-top: 20px;
margin-bottom: 20px;
font-family: 'Poppins';
font-weight: 400;
}

.save-file-btn{
    background-color: #4A10A4!important;
    color: #fff!important;
    border-radius: 10px!important;
    text-transform: capitalize!important;
    font-size: 15px!important;
    box-shadow: none!important;
    font-family: 'Poppins'!important;
    font-weight: 400!important;
    height: 40px;
    width: 100px!important;
}

.upload-questionnaire .mb-28{
    margin-bottom: 28px!important;
}

.css-139lcja .bgaddfiles{
    background-color: #242526;
    color: white;
}
.css-139lcja .heading-main{
    color: white;
}