.vendor-information{
    background-color: #fff;
    padding: 10px 35px 35px 35px;
    border-radius: 15px;
    margin-top: 60px;
}

.relation{
    color: #242526;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    margin-top: 25px;
}

.btn-right {
    text-align: right;
}


.m-l {
    margin: 0px 20px 0!important;
}

.m-l-0{
margin: 0px 0px 0px 0px!important;
}

.d-flex-back-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.vendor-information-head{
    color: #242526;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 10px;
}

.vendor-information-head span{
    border: 1px solid #dbc5ff;
    width: 40px;
    display: inline-block;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
    border-radius: 50%;
    background: #f5f6f7;
    color: #4a10a4;
    line-height: 39px;
    font-weight: 500;
}

.line-hr {
    border-top: 1px solid #E4E6EA;
    display: block;
    width: 100%;
    margin-top: 10px;
}

.dark-mode-class .vendor-information{
    background: #242526;
    border: 1px solid #3A3B3C;
}

.dark-mode-class .vendor-information-head{
    color: #fff;
}
.dark-mode-class .line-hr{
    border-top: 1px solid #3A3B3C; 
}
.dark-mode-class .relation{
    color: #fff;
}

.mt-0{
    margin-top: 0px;
    margin-bottom: 0;
}

.vendor-information p {
    font-size: 14px;
    color: #242526;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
}

.relation svg {
    vertical-align: middle;
    color: #7c7c7d;
}

.dark-mode-class .relation svg{
color: #fff!important;
}

.MuiCheckbox-colorPrimary.Mui-checked{
    color: #4a10a4!important;
}