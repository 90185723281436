.loader{
    z-index: 10000;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffffa6;
    left: 0;
    top: 0;
}

.loader .css-k008qs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.loader .css-18lrjg1-MuiCircularProgress-root{
color: #4A10A4!important;
}

.dark-mode-class .loader svg{
    color: #4A10A4!important;
}