.tabs-bg{
    background-color: #fff;
    padding: 35px 35px;
    border-radius: 15px;
    margin-top: 40px;
    position: relative;
}
.preview-popup.css-19owhuc .cross-right-side svg{
    color: #242526;
}

.css-139lcja .cross-right-side svg{
    color: #fff;
}

.inline-parts{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.tabpanel-full .MuiBox-root{
    width: 100%;
    padding-top: 0px;
    padding-right: 0px;
}

.w100-textarea{
width: 100%;
color: #242526;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
border: 1px solid #E4E6EA;

}

.cross-right-side {
    width: auto;
    color: #212121!important;
    position: fixed!important;
    right: 160px;
    top: 43px;
}

.preview-popup {
    max-width: 75%;
    margin-inline: auto;
    max-height: 560px;
    overflow-y: auto;
    overflow-x: hidden;
}

.cross-right-side:hover{
    background-color: transparent!important;
}

.inline-parts h3{
    margin-top: 0px;
}

.tabs-bg .MuiTab-root{
text-transform: capitalize;
font-size: 15px;
color: #242526;
font-family: 'Poppins';
font-style: normal;
font-weight:200;
padding-left: 0;
text-align: left;
flex-direction: revert;
justify-content:left;
}

.tabs-bg.preview-popup.MuiBox-root.css-139lcja .MuiTab-textColorPrimary{
    color: #fff;
}

.tabs-bg .MuiTab-root svg{
margin-right: 5px;
width: 20px;
height: 20px;
}

.question-part{
    width: 100%;
}


.dark-mode-class .question-part p{
    font-size: 16px;
    color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
}
.light-mode-class .question-part p{
    font-size: 16px;
    color: #242526;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
}

.tabpanel-full{
    width: 100%;
}
.tabs-bg .Mui-selected{
    color: #4a10a4!important;
    font-weight: 900!important;
}

.dark-mode-class .tabs-bg .Mui-selected{
    color: #fff!important;
}

.tabs-bg .MuiTabs-indicator{
    background-color: #4a10a4!important;
}

.tabs-bg .MuiTabs-vertical{
    max-width: 180px;
    min-width: 180px;
    border-color: #E4E6EA!important;
}

.MuiRadio-colorPrimary.Mui-checked{
    color: #4a10a4!important;
}

.modal-popup .MuiDialog-paper{
    min-width: 700px;
    padding: 25px 35px;
    text-align: center;
    border-radius: 15px;
}

.send-assessments{
    font-family: 'Poppins';
    font-style: normal;
    font-weight:500;
    font-size: 25px;
    line-height: 35px;
    color: #242526;
}

.model-btns{
    width:100px;
}

.dark-mode-class .tabs-bg{
    background: #242526;
    border: 1px solid #3A3B3C;
}

.dark-mode-class .w100-textarea{
    background: #242526;
    border: 1px solid #3A3B3C;
    color: #fff;
}

.dark-mode-class .tabs-bg .MuiTab-root{
color: #fff;
}

.dark-mode-class .tabs-bg .MuiTabs-vertical{
border-color:#3A3B3C!important;
}

.dark-modal .send-assessments{
color: #fff;
}

.dark-mode-class .tabs-bg .Mui-selected{
    background-color: #242526!important;
}

.btn-right a {
    text-decoration: none;
}

.cancel-btn{
    /* background-color: #ffffff!important; */
    border: 1px solid #4a10a4!important;
    /* color: #4a10a4!important; */
    font-weight: 400!important;
}

.center-vendor{
    margin: 0px auto!important;
    
}

.passed{
    font-family: 'Poppins'!important;
    font-size: 15px;
    font-weight: 400!important;
    margin-top: 18px!important;
}

.set-view{
    margin-bottom: 10px;
    margin-left: 5px;
}

.dark-mode-class .view-file svg {
color: #fff;
}

.dark-mode-class .MuiRadio-colorPrimary.Mui-checked{
    color: #fff!important;
}

.passed-color{
    background: #2e7d32!important;
    line-height: 25px!important;
    font-size: 14px!important;
}

.failed-color{
    background: #d32f2f!important;
    line-height: 25px!important;
    font-size: 14px!important;
}

.right-view {
    text-align: right;
}

.padding-square{
    padding: 20px;
}

.add-section {
    width: 110px;
    height: 40px;
    padding: 0!important;
    margin: 0px 10px 20px auto!important;
}

.chat-circle-right{
    background-color: #4a10a4;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    color: #fff;
    line-height: 80px;
    text-decoration: none;
    font-size: 13px;
    text-align: center;
    overflow-y: hidden;
}

.pop-tabs-width{
    width: 95%;
    margin-inline:auto;
}

.preview-question-heading{
 font-size: 16px;
 font-weight: 500;
 padding: 0;
 margin: 10px 0;
 min-width: 44px;
}

.data-text-preview{
    font-weight: 400;
    font-size: 16px;
}

.line-h-0{
    line-height: 0;
}

.tabpanel-full-size{
    width: 100%;
}

.flex-preview-tab{
    display: flex;
    align-items: baseline;
}

 /* .css-argv3p .dark-mode-class{
    background: #242526;
    border: 1px solid #3A3B3C;
} */

/* .css-argv3p .cross-right-side svg{
    color: #fff;
} */
.cross-right {
    width: 100%;
    display: flex;
    justify-content: right;
    padding: 0;
    color: #212121;
}

.cross-right:hover{
background-color: transparent;
}

.radio-text-size-label{
    font-size: 16px;
}

.score-selected{
    width: fit-content;
    padding: 4px 10px;
    border: 1px solid #E4E6EA;
    margin: 10px 0;
}

.dark-mode-class .score-selected{
    border:1px solid #3A3B3C;
}

.mui-icons-questionnaire button {
    background: #f5f6f7;
    text-align: center;
    margin: auto;
    height: 40px;
    width: 45px;
    min-width: 45px;
}

.dark-mode-class .mui-icons-questionnaire button{
    background: #242526;
    border: 1px solid #515151;
}

.dark-mode-class .mui-icons-questionnaire button svg{
    color: #fff;
}

.mui-icons-questionnaire svg {
    margin-right: 0;
}

.space-file{
    margin-left: 10px;
}

.deletequestion{
    max-width: 42%;
}

.deletequestion h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #242526;
    text-align: center;
    margin-bottom: 30px;
}

.deletequestion-btn {
    width: 145px;
    height: 40px;
    padding: 0!important;
    display: flex;
    justify-content: center;
}
.cross-rights{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

}

.preview-popup::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.preview-popup::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.preview-popup::-webkit-scrollbar-thumb{
	background-color: #242526;
}

.mbtop-manage {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 30px!important;
    width: 100%;
}

.preview-pop-heading{
    text-align: left;
    margin-bottom: 22px;
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 16px;
}

.bg-dark-assessment .css-1ktfdg8-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter,
.bg-dark-assessment .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter{
    margin: 8px 8px 0;
}

.MuiDataGrid-main.css-204u17-MuiDataGrid-main{
    font-family: 'Poppins';
}

/* .bg-dark-assessment .css-gvpb0f-MuiDataGrid-root .MuiDataGrid-columnHeader, 
.bg-dark-assessment .css-gvpb0f-MuiDataGrid-root .MuiDataGrid-cell{
    padding: 0 0px 0 20px;
} */

.MuiDataGrid-withBorderColor{
    width: 100%;
}

@media (min-width: 1367px){
    .cross-right-side{
        right: 190px;
    }
}

@media (max-width: 767px){
    .deletequestion-btn {
        width: 119px;
    }
    .tabs-bg .MuiBox-root{
        display: block;
        padding-left: 0px;
    }
    .tabs-bg .MuiTabs-vertical {
        max-width: 100%;
        min-width: 100%;
    }
    .preview-popup {
        max-width: 90%;
    }
}