.fullWidthTextarea{
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    border-radius: 15px!important;
    padding: 16.5px 14px;
    border-color: #B1B3B7;
    font-size: 16px;
}

.w-100{
    width: 100%;
    height: 100px;
    margin: 0!important;
}

.btn-arrange {
    display: flex;
    justify-content: right;
    flex-direction: row;
    width: 100%;
}

.dark-mode-class .bg-dark-assessment{
    background-color: #242526;
}

.btn-arrange button{
 margin:20px 0px 0 15px!important;
}

textarea:focus-visible{
outline: -webkit-focus-ring-color auto 0px;
}

.dark-mode-class textarea{
background-color: #242526;
/* border: 1px solid #3A3B3C; */
color: #fff;
}


.mb10{
    margin-bottom: 10px;
}

textarea::placeholder, 
.MuiSelect-select input::placeholder{
    color: #B1B3B7!important;
}

input::placeholder{
    color: #B1B3B7!important;
}

.MuiBox-root.css-wb8uet {
    width: 85px;
}
.MuiBox-root.css-zxo5k6 {
    width: 85px;
}

.MuiBox-root.css-12nk3c2 {
    width: 85px;
}

.MuiBox-root.css-ctmaoe {
    width: 85px;
}


.css-1b1gipw-MuiFormControl-root-MuiTextField-root {
    color: #000;
}