/* .logo-center{
    display: block;
    text-align: center;
    margin: 0px auto;
    width: 150px;
    margin-bottom: 30px;
}
.quest-base{
display: flex;
}
.quest-base p {
    padding: 0px 15px;
}
.full-width{
    width: 100%;
}

.logo-image-policy{
    width: 150px;
    height: 150px;
}

.logo-image-policy{
    width: 140px;
    height: 100px;
    background-color: grey;
    margin: 0px 15px;
}

.flex-policy{
    display: flex;
}

.statics-links{
    width: 100%;
}

.d-flex-logo{
    display: flex;
    justify-content: left;
    padding-left: 5px;
}

.logos {
    text-align: center;
    margin: 10px;
    border: 1px solid #e1e1e1;
    padding: 3px;
    width: 149px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.logos h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.document-boxs{
    display: flex;
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.document-boxs p{
    margin: 0;
    line-height: 0;
}

.padding15{
    padding: 15px;
}*/


/*NEW CSS*/

.custom-table-q-base .MuiBox-root.css-19kzrtu {
    padding-left: 0px;
    padding-right: 0px;
}

.questbase-flex {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.quest-100 {
    width: 100%;
}

.flex-two-two-button {
    display: flex;
}

.m-r-shift {
    margin-right: 10px !important;
}

.icons-align {
    display: flex;
    gap: 6px;
    justify-content: left;
    margin-bottom: 10px;
    margin-top: 5px;
}

.light-mode-class .icons-align svg {
    height: 20px;
    width: 20px;
}
.upload-tab-btns button{
    color: #4a10a4;
}


.icons-upload button{
    color: #4a10a4;
}
.light-mode-class .icons-upload button{
    background: #f5f6f7 !important;
    height: 40px;
}

.dark-mode-class .icons-upload button {
    background: #242526;
    border: 1px solid #515151;
}

.light-mode-class .icons-align button{
    background: #f5f6f7 !important;
    height: 40px;
}

.dark-mode-class .icons-align button {
    background: #242526;
    border: 1px solid #515151;
}
.full-form{
    padding: 20px;
}

.accordion-part{
    margin: 14px 0px;
}

/* .light-mode-class .accordion-part{
        border: 1px solid #c9c3c3;
        border-radius: 13px !important;
} */

.dark-mode-class textarea {
    background-color: #242526;
    border: 1px solid #3A3B3C;
    color: #fff;
}
@media (min-width: 600px){
.css-ur2jdm-MuiContainer-root {
    padding-bottom: 18px;

}}

.no-logo{
    margin: 0px 0px 14px 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 1px solid;
}

.accordion-part .accordion {
    margin-bottom: 0px;
}