.heading-main{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    color: #242526;
    margin-top: 0px;
    text-align: center;
}

.profile-form .MuiPaper-rounded{
    min-width: 100%;
    padding: 50px 35px !important;
    margin-top: 14px!important;
}

.profile-form .css-fvtbp6-MuiPaper-root-MuiCard-root{
    background: #1E1E1E!important;
}

.profile-form .css-fvtbp6-MuiPaper-root-MuiCard-root .heading-main{
    color: #fff!important;
}

.profile-form .css-fvtbp6-MuiPaper-root-MuiCard-root .gender{
    color: #fff!important;
}

.profile-form .css-fvtbp6-MuiPaper-root-MuiCard-root .MuiIconButton-colorSecondary{
    color: #fff!important;
}
.css-1nu30bq-MuiStack-root{
    min-width: 100%;
}

.upload-new{
    width: 150px;
    font-weight: 400!important;
    height: 40px;
    text-transform: capitalize!important;
    font-size: 15px!important;
    font-family: 'Poppins'!important;
    background: #4A10A4!important;
    color: #fff!important;
    border-radius: 10px!important;
    display: flex!important;
    margin: 18px auto 0px!important;
}



.flex-inline {
    display: flex;
}

.MuiFormGroup-root{
    display: block!important;
}
.MuiFormGroup-root .MuiTypography-body1{
    font-size: 15px!important;
}

.MuiRadio-colorSecondary.Mui-checked{
    color: #4A10A4!important;
}

.MuiOutlinedInput-input{
color: #B1B3B7!important;
}

input::placeholder{
    color: #B1B3B7!important; 
}
input:-ms-input-placeholder{
    color: #B1B3B7!important;
}

.center .upload-new{
    margin: 0px auto;
}

.center-upload img{
    max-height: 85%;
    max-width: 85%;
    object-fit: fill;
    border-radius: 15px;
}

.gender{
    font-size: 17px!important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    /* color: #242526!important; */
}

.gender-value {
    margin-top: 4px!important;
}

.css-1ludl2-MuiStack-root {
    padding: 8px 0!important;
}

.profile-form .profile-icon {
    margin: 0px auto 10px;
    display: block;
}

.rightside{
    margin: 20px 0px 0px auto!important;
    text-align: center;
    display: flex!important;
}

.dark-mode-class.upload-new-plus{
    background: #fff !important;
    color: #000 !important;
    border: 1px dashed #000 !important;
    height: 40px;
    padding: 15px!important;
    margin: 10px auto!important;
    display: flex!important;
    width: fit-content;
    font-size: 15px!important;
    font-weight: 400!important;
}

.light-mode-class.upload-new-plus{
    background: #000 !important;
    color: #fff !important;
    border: 1px dashed #fff !important;
    height: 40px;
    padding: 15px!important;
    margin: 10px auto!important;
    display: flex!important;
    width: fit-content;
    font-size: 15px!important;
    font-weight: 400!important;
}

.upload-new-plus img{
width: 15px;
height: 15px;
margin-right: 5px;
}

.css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected:hover {
    background-color: rgb(74 16 164)!important;
}

.css-swe8o6-MuiButtonBase-root-MuiToggleButton-root,
.css-d9c359-MuiButtonBase-root-MuiToggleButton-root{
    text-transform: capitalize!important;
    font-size: 15px!important;
    font-family: 'Poppins'!important;
    font-style: normal;
    font-weight: 400;
}

.dark-mode-class .Mui-selected{
    background-color: rgb(74 16 164)!important;
    color: #fff!important;
}

.light-mode-class .gender-area .Mui-selected{
    background-color: rgb(74 16 164)!important;
    color: #fff!important;
}

.dark-mode-class ::-webkit-calendar-picker-indicator {
    filter: brightness(0) saturate(100%) invert(100%) sepia(23%) saturate(1192%) hue-rotate(160deg) brightness(115%) contrast(109%);
}

.gender-area{
display: flex;
align-items: center;
}

.gender-area .gender{
    margin-right: 15px;
    font-size: 15px!important;
    font-family: 'Poppins'!important;
    font-style: normal;
    font-weight: 400;
}

.eye-part{
    margin-right: 5px!important;
}

@media (max-width: 767px){
    .gender-area{
        justify-content: space-between;
    }
.profile-form .MuiPaper-rounded{
 width: 100%;
 padding: 20px!important;
}
}


.css-mhc70k-MuiGrid-root>.MuiGrid-item {
    padding-left: 6px !important;
}