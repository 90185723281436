.login-screen {
  margin: 0px auto;
  background: url('../../assets/login-background.svg');
  min-height: 100vh;
  display: flex!important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  background-size: cover;
  background-position: 100% 100%;
}

.box-login {
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}


.css-18yadix {
  min-height: 0px !important;
  min-width: 450px;
  padding-top: 20px !important;
  text-align: center;
  padding-bottom: 0 !important;
}

.login-screen input {
  background: #FFFFFF;
  border: 1px solid #E4E6EA;
  border-radius: 15px;
  color: #B1B3B7;
  height: 50px;
  padding: 0px 45px;
}
@media (max-width: 767px) {
  .css-18yadix {
      min-width: 100%;
  }
}
.login-screen input:hover {
  background-color: transparent;
}

.login-screen h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  color: #242526;
  margin-bottom: 0px;
}

.login-screen h4 {
  margin-bottom: 0px;
}

.login-screen p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #B1B3B7;
  margin-top: 5px;
  margin-bottom: 40px;
}

.login-screen button {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  width: 180px;
  height: 40px;
  background-color: #4A10A4 !important;
  border-radius: 10px;
  color: #fff;
  text-transform: capitalize;
}

.flex-direction {
  flex-direction: column !important;
}

.flex-direction .forgot-text {
  font-family: 'Poppins' !important;
  font-style: normal;
  /* font-weight: 400 !important; */
  font-size: 15px !important;
  line-height: 20px;
  text-align: center;
  color: #242526;
  margin-top: 15px;
  display: block;
  margin-bottom: 30px;
  text-decoration: none;
}

.flex-direction .dont-have {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #B1B3B7;
  text-decoration: none;
  margin-bottom: 5px;
  display: inline-block;
  margin-bottom: 15px;
}

.dont-have a {
  font-size: 15px !important;
}

.eye-icon {
  position: absolute;
  right: 15px;
}

.eye-icon button {
  background-color: transparent !important;
  color: #242526;
  width: auto;
  z-index: 1;
}

.body-dark .eye-icon button {
  color: #fff;
}

.body-dark input::placeholder {
  color: #ccc8c8 !important;
  opacity: 1;
}

.body-light input::placeholder {
  color: #282829 !important;
  opacity: 1;
}


.css-rb5gc9-MuiFormLabel-root-MuiInputLabel-root {
  color: #B1B3B7 !important;
  left: 32px !important;
  top: 4px !important;
}

.inputfield {
  position: relative;
  width: 100%;
}

.inputfield img {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 13px;
  top: 17px;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiInputBase-root {
  padding: 0px !important;
  border-radius: 15px !important;
}

.sinup-bold {
  margin-left: 5px !important;
  display: inline-block;
  color: #242526 !important;
  text-decoration: underline !important;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 400;
}

.css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #e8f0fe inset !important;
  -webkit-text-fill-color: #242526 !important;
  caret-color: #fff;
  border-radius: 15px !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #e8f0fe inset !important;
  -webkit-text-fill-color: #242526 !important;
  caret-color: #fff;
  border-radius: 15px !important;
}

.body-dark input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #3c3c3c inset !important;
  -webkit-text-fill-color: #fff !important;
  caret-color: #fff;
}

.login-screen .MuiTextField-root {
  margin: 0px 0 20px !important;
  width: 100% !important;
}

.already-have {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #B1B3B7;
}

.already-have a {
  color: #242526;
  text-decoration: underline;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 15px !important;
}

.css-1lwbda4-MuiStack-root>:not(style)+:not(style) {
  margin-top: 10px !important;
}

body .mb-2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  color: #242526;
  margin-bottom: 15px;
}

body .space-bottom {
  margin-bottom: 20px;
}

.back-to {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.body-dark .back-to {
  color: #fff;
}

.body-light .back-to {
  color: #000000;
}

.achor {
  text-decoration: none !important;
}

.body-light .back-to img {
  width: 20px;
  height: 20px;
}
.body-dark .back-to img {
  filter: brightness(0) saturate(100%) invert(78%) sepia(0%) saturate(1918%) hue-rotate(26deg) brightness(93%) contrast(92%);
  width: 20px;
  height: 20px;
}

.relative {
  position: relative;
}
.dark-toggle {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
  border-radius: 0px 0px 15px 15px;
  width: 316px;
  margin: 0px auto !important;
  text-align: center;
  display: flex !important;
  justify-content: space-between;
  bottom: -61px;
  height: 40px;
  flex-direction: row-reverse;
  padding: 20px;
  font-size: 15px;
  line-height: 20px;
  color: #242526;
}
@media (max-width: 767px) {
  .dark-toggle {
      width: 240px;
      bottom: -64px;
  }
}
.dark-toggle .MuiTypography-root {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.dark-toggle .css-1yjjitx-MuiSwitch-track {
  background-color: #a3a3a3;
}
.body-dark .dark-toggle{
  background-color: #18191A!important;
  border: 1px solid #3A3B3C;
  color: #fff;
}


.css-1yjjitx-MuiSwitch-track {
  background-color: #a7a8a8 !important;
}

.css-mghcik-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #4a10a4 !important;
}

.dark-toggle .Mui-checked .MuiSwitch-thumb {
  color: #fff !important;
}

.MuiSwitch-thumb {
  width: 13px !important;
  height: 13px !important;
  margin: 4px 4px !important;
}

.body-dark {
  background-image: url('../../assets/darklogin.svg');
  background-size: cover;
  background-position: 100% 100%;
}

.body-dark .box-login {
  background-color: #242526;
  border: 1px solid #3A3B3C;
}

.body-dark h2 {
  color: #fff;
}

.body-dark .inputfield input {
  background-color: #18191A !important;
  border: 1px solid #3A3B3C;
}
.body-light .inputfield input {
  color: #18191A !important;
  border: 1px solid #3A3B3C;
}

.body-dark .inputfield img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(23%) saturate(1192%) hue-rotate(160deg) brightness(115%) contrast(109%);
}




.logo-size {
  width: 144.97px;
  height: 50px;
  text-align: center;
  margin: 0px auto;
}

.body-dark .forgot-text,
.body-dark .sinup-bold {
  color: #fff !important;
}

.body-dark .already-have a {
  color: #fff !important;
  margin-bottom: 15px;
  font-size: 15px !important;
}

.css-161s7uk-MuiGrid-root {
  margin-bottom: 0px !important;
}

.MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #242526 !important;
  border-width: 1px !important;
}

.body-dark .MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.f2a .MuiPaper-elevation {
  padding: 0px !important;
  background: #fff;
}

.f2a p {
  margin-bottom: 0px !important;
}

.input-style input {
  padding: 24px !important;
  font-size: 20px;
}

.fa-center {
  display: flex;
  justify-content: center;
}

.center-alignbtn {
  padding-bottom: 25px;
}

.fa-center h2 {
  margin-top: 0px !important;
}

.reset-passwords {
  padding: 0px 25px 25px;
}

.enter-new-password {
  margin-bottom: 40px !important;
}

@media screen and (max-height: 800px){
  .register-scroll{
    height: 100vh;
    overflow-y: auto;
    display: block;
    padding-top: 15px;
  }
}