.table-container-show-file {
    margin: 30px;
    border-radius: 5px;
    overflow: auto;
    background-color: none;
}


.excel-table {
    width: 100%;
    border: 1px solid #ccc;
    border-collapse: collapse;
}

.excel-table-header {
    background-color: #f0f0f0;
    font-weight: bold;
}

.excel-table td,
.excel-table th {
    border: 1px solid #ccc;
    padding: 8px;
}

.excel-table tr:nth-child(even) {
    background-color: #f2f2f2;
}